<template>
  <v-card class="ma-4">
    <v-card-title style="text-align: left"
      >Listagem das principais inconformidades</v-card-title
    >
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          v-if="
            relatorio.step_8_ambiental_de_descanso ||
            relatorio.step_8_sala_de_ordenha ||
            relatorio.step_8_limpeza_do_equipamento ||
            relatorio.step_8_desregulagem_do_tanque ||
            relatorio.step_8_tanque_de_resfriamento ||
            relatorio.step_8_coleta_do_leite_superior_a_48_horas ||
            relatorio.step_8_atraso_no_resfriamento_do_leite
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Inconformidades</span>
          <v-col v-if="relatorio.step_8_ambiental_de_descanso" cols="12">
            <span class="value-title"
              >Ambiente de descanso dos animais com acúmulo de barro
            </span>
          </v-col>
          <v-col v-if="relatorio.step_8_sala_de_ordenha" cols="12">
            <span class="value-title"
              >Sala de ordenha sem higienização adequada
            </span>
          </v-col>
          <v-col v-if="relatorio.step_8_limpeza_do_equipamento" cols="12">
            <span class="value-title"
              >Limpeza do equipamento de ordenha inadequada
            </span>
          </v-col>
          <v-col v-if="relatorio.step_8_desregulagem_do_tanque" cols="12">
            <span class="value-title"
              >Desregulagem do tanque de resfriamento (termostato)
            </span>
          </v-col>
          <v-col v-if="relatorio.step_8_tanque_de_resfriamento" cols="12">
            <span class="value-title"
              >Tanque não refrigera o leite a 4 ºC em duas horas
            </span>
          </v-col>
          <v-col
            v-if="relatorio.step_8_coleta_do_leite_superior_a_48_horas"
            cols="12"
          >
            <span class="value-title"
              >Coleta do leite superior a 48 horas
            </span>
          </v-col>
          <v-col
            v-if="relatorio.step_8_atraso_no_resfriamento_do_leite"
            cols="12"
          >
            <span class="value-title"
              >Atraso no resfriamento do leite (Balde ao pé, Ordenha manual)
            </span>
          </v-col>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ManejoReprodutivo',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    maskIdentify(raw) {
      return raw ? raw.split('|') : ['Nenhum animal identificado']
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
  line-height: 40px;
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
