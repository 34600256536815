<template>
  <v-card class="ma-4">
    <v-card-title style="text-align: left">Principais Indicadores</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <v-col cols="12" sm="4" lg="4" xl="4" class="col-bordered">
          <span class="key-title">Produção média (l/dia): </span>
          <span class="value-title">
            {{
              relatorio.ProducaoMedia
                ? relatorio.ProducaoMedia
                : 'Não informado'
            }}
          </span>
        </v-col>
        <v-col cols="12" sm="4" lg="4" xl="4" class="col-bordered">
          <span class="key-title">Proteína média:</span>
          <span class="value-title">
            {{
              relatorio.ProteinaMediaUltimoMes
                ? relatorio.ProteinaMediaUltimoMes + ' %'
                : 'Não informado'
            }}
          </span>
        </v-col>
        <v-col cols="12" sm="4" lg="4" xl="4" class="col-bordered">
          <span class="key-title">Gordura média:</span>
          <span class="value-title">{{
            relatorio.GorduraMediaUltimoMes
              ? relatorio.GorduraMediaUltimoMes + ' %'
              : 'Não informado'
          }}</span>
        </v-col>
        <v-col cols="12" sm="4" lg="4" xl="4" class="col-bordered">
          <span class="key-title">CPP média: </span>
          <span class="value-title">{{
            relatorio.CppMedia
              ? relatorio.CppMedia + ' UFC/ml * 10³'
              : 'Não informado'
          }}</span>
        </v-col>
        <v-col cols="12" sm="4" lg="4" xl="4" class="col-bordered">
          <span class="key-title">CPP após a visita: </span>
          <span class="value-title">{{
            relatorio.finalizedCpp
              ? relatorio.finalizedCpp + ' UFC/ml * 10³'
              : 'Não informado'
          }}</span>
        </v-col>
        <v-col cols="12" sm="4" lg="4" xl="4" class="col-bordered">
          <span class="key-title">CCS média: </span>
          <span class="value-title">{{
            relatorio.CcsMedia
              ? relatorio.CcsMedia + ' céls/ml * 10³'
              : 'Não informado'
          }}</span>
        </v-col>
        <v-col cols="12" sm="6" lg="6" xl="6" class="col-bordered">
          <span class="key-title">Porcentagem de conformidades: </span>
          <span class="value-title">{{
            relatorio.conformPercentege
              ? relatorio.conformPercentege + ' %'
              : 'Não informado'
          }}</span>
        </v-col>
        <v-col cols="12" sm="6" lg="6" xl="6" class="col-bordered">
          <span class="key-title">Porcentagem de não conformidades: </span>
          <span class="value-title">{{
            relatorio.notConformPercentege
              ? relatorio.notConformPercentege + ' %'
              : 'Não informado'
          }}</span>
        </v-col>
        <v-col v-if="relatorio.TipoOrdenha" cols="12" class="col-bordered">
          <span class="key-title">Tipo de ordenha: </span>
          <span v-if="relatorio.TipoOrdenhaManual" class="value-title">{{
            'Manual'
          }}</span>
          <span v-if="relatorio.TipoOrdenhaBaldeAoPe" class="value-title">{{
            'Balde ao pé'
          }}</span>
          <span v-if="relatorio.TipoOrdenhaTransferidor" class="value-title">{{
            'Transferidor'
          }}</span>
          <span v-if="relatorio.TipoOrdenhaLinhaBaixa" class="value-title">{{
            'Circuito fechado / linha baixa'
          }}</span>
          <span v-if="relatorio.TipoOrdenhaLinhaMedia" class="value-title">{{
            'Circuito fechado / linha média'
          }}</span>
          <span v-if="relatorio.TipoOrdenhaLinhaAlta" class="value-title">{{
            'Circuito fechado / linha alta'
          }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { convertNumberBR } from '../../../../utils/masks'
export default {
  name: 'IndicadoresQualidade',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: { convertNumberBR },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
