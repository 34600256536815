import HtmlToCanvas from 'html2canvas'
import JsPDF from 'jspdf'
import moment from 'moment'
import { ENV_APP } from '../../../env'
import { convertNumberBR } from '../../utils/masks'

const utilsRelatorio = {
  getDataAtual() {
    var monName = [
      'janeiro',
      'fevereiro',
      'março',
      'abril',
      'maio',
      'junho',
      'julho',
      'agosto',
      'setembro',
      'outubro',
      'novembro',
      'dezembro',
    ]
    var now = new Date()

    var str =
      'Relatório gerado em ' +
      now.getDate() +
      ' de ' +
      monName[now.getMonth()] +
      ' de ' +
      now.getFullYear() +
      ' às ' +
      formatDate(now, 'HH:mm:ss') +
      '.'
    return str
  },
  calcLacVacas(relatorio) {
    if (relatorio.VacasLactacao && relatorio.VacasSecas) {
      return `${convertNumberBR(
        100 *
          (parseInt(relatorio.VacasLactacao) /
            (parseInt(relatorio.VacasLactacao) +
              parseInt(relatorio.VacasSecas))),
      )} %`
    } else {
      return '-'
    }
  },
  calcLacRebanho(relatorio) {
    var totalRebanho = 0
    if (relatorio.VacasLactacao) {
      totalRebanho += parseInt(relatorio.VacasLactacao)
    }
    if (relatorio.VacasSecas) {
      totalRebanho += parseInt(relatorio.VacasSecas)
    }
    if (relatorio.Novilhas) {
      totalRebanho += parseInt(relatorio.Novilhas)
    }
    if (relatorio.Recria) {
      totalRebanho += parseInt(relatorio.Recria)
    }
    if (relatorio.Aleitamento) {
      totalRebanho += parseInt(relatorio.Aleitamento)
    }
    if (relatorio.Machos) {
      totalRebanho += parseInt(relatorio.Machos)
    }
    if (totalRebanho > 0) {
      return `${convertNumberBR(
        100 * (parseInt(relatorio.VacasLactacao) / totalRebanho),
      )} %`
    } else {
      return '-'
    }
  },
  calcProdutividade(relatorio) {
    if (relatorio.VacasLactacao && relatorio.ProducaoMedia) {
      return `${convertNumberBR(
        parseInt(relatorio.ProducaoMedia) / parseInt(relatorio.VacasLactacao),
      )} L / dia`
    } else {
      return '-'
    }
  },
  calcProdutividadeTotal(relatorio) {
    var totalVacas = 0
    if (relatorio.VacasLactacao) {
      totalVacas += parseInt(relatorio.VacasLactacao)
    }
    if (relatorio.VacasSecas) {
      totalVacas += parseInt(relatorio.VacasSecas)
    }
    if (relatorio.ProducaoMedia && totalVacas > 0) {
      return `${convertNumberBR(
        parseInt(relatorio.ProducaoMedia) / totalVacas,
      )} L / dia`
    } else {
      return '-'
    }
  },
}

function formatDate(date, format = 'DD/MM/YYYY') {
  return moment(date).format(format)
}

function renderCheckbox(doc, text, cursor, config) {
  const {
    smallBoxHeight,
    line,
    smallGutterX,
    smallGutterY,
    startX,
    tableLimit,
  } = config

  doc.rect(startX, cursor, tableLimit, line, 'F')

  doc.rect(startX, cursor, line, smallBoxHeight, 'F')

  doc.text(text, startX + smallGutterX, cursor + smallGutterY)

  doc.rect(startX + tableLimit, cursor, line, smallBoxHeight, 'F')

  doc.rect(startX, cursor + smallBoxHeight, tableLimit, line, 'F')
}

function renderCheckboxList(doc, text, cursor, config) {
  const {
    smallBoxHeight,
    line,
    smallGutterX,
    smallGutterY,
    startX,
    tableLimit,
  } = config

  //doc.rect(startX, cursor, tableLimit, line, 'F')

  doc.rect(startX, cursor, line, smallBoxHeight, 'F')

  doc.text(text, startX + smallGutterX, cursor + smallGutterY)

  doc.rect(startX + tableLimit, cursor, line, smallBoxHeight, 'F')

  //doc.rect(startX, cursor + smallBoxHeight, tableLimit, line, 'F')
}

function renderRecomendacoes(doc, text, data, cursor, config) {
  const {
    smallBoxHeight,
    line,
    smallGutterX,
    smallGutterY,
    gutterY,
    startX,
    tableLimit,
  } = config

  if (!data) return cursor

  data = data || '-'
  const width = doc.getTextWidth(data)
  const numberLines = parseInt(width / (tableLimit - smallGutterX * 2) + 2)

  cursor = checkAddPage(doc, cursor, smallBoxHeight * numberLines, config)

  doc.rect(startX, cursor, line, smallBoxHeight * numberLines, 'F')

  doc.rect(startX, cursor, tableLimit, line, 'F')

  doc.setFont('helvetica', 'normal')
  doc.text(text, startX + smallGutterX, cursor + smallGutterY)

  doc.text(data, startX + smallGutterX, cursor + gutterY, {
    maxWidth: tableLimit - smallGutterX * 2,
  })

  doc.rect(startX + tableLimit, cursor, line, smallBoxHeight * numberLines, 'F')

  doc.rect(startX, cursor + smallBoxHeight * numberLines, tableLimit, line, 'F')

  return cursor + smallBoxHeight * numberLines
}

function checkAddPage(doc, cursor, area, config) {
  const { heightLimit, margin, tableLimit, line, startX } = config

  if (cursor + area > heightLimit) {
    doc.addPage()
    cursor = margin
    doc.rect(startX, cursor, tableLimit, line, 'F')
  }

  return cursor
}

function header(doc, config, relatorio) {
  const logo1 = new Image()
  const logo2 = new Image()

  logo1.src = config.logo

  doc.addImage(logo1, 'PNG', config.margin, config.margin, 70, 55)

  if (relatorio.logoagroindustria) {
    logo2.src = relatorio.logoagroindustria
    doc.addImage(logo2, 'PNG', config.tableLimit - 50, config.margin, 73, 50)
  }

  doc.setFontSize(config.largefontSize).setFont('helvetica', 'bold')

  const widthOfString = doc.getTextWidth('Relatório CPP')

  doc.text(
    'Relatório CPP',
    config.tableLimit / 2 - widthOfString / 2 + config.margin,
    config.margin + 30,
  )

  doc.setFontSize(config.fontSize).setFont('helvetica', 'normal')
}

function subHeader(doc, _ref, _ref2) {
  const {
    fazenda: {
      produtor: { Nome: produtor },
      pessoajuridica: { NomeFantasia: fazenda },
      producer: { Matricula: matricula },
    },
    consultor: {
      pessoafisica: { Nome: consultor },
    },
    cidadefazenda,
    DataAtendimento,
    HoraInicio,
    HoraFim,
    NumeroVisita,
  } = _ref
  const {
    boxHeight,
    gutterY,
    line,
    lineColor,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
    mediumFontSize,
    fontSize,
  } = _ref2

  doc.setFillColor(lineColor)

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Informações básicas', startX, startY + 10)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  doc.rect(startX, startY + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight, line, boxHeight, 'F')

  doc.text('Fazenda:', startX + smallGutterX, startY + smallGutterY + boxHeight)

  doc
    .setFont('helvetica', 'bold')
    .text(fazenda, startX + smallGutterX, startY + gutterY + boxHeight)
    .setFont('helvetica', 'normal')

  doc.rect(startX, startY + boxHeight * 2, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX + tableLimit, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Produtor(a):',
    startX + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    produtor.substring(0, 25),
    startX + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 3, tableLimit, line, 'F')

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Matrícula:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    matricula ? matricula : '-',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.text(
    'Consultor(a):',
    startX + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(consultor, startX + smallGutterX, startY + boxHeight * 3 + gutterY)

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Cidade:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    cidadefazenda ? cidadefazenda.Nome : '-',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 4, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 4, line, boxHeight, 'F')

  doc.text(
    'Data:',
    startX + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    DataAtendimento,
    startX + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 4,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Horário do início:',
    startX + tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    HoraInicio,
    startX + tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Horário do término:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    HoraFim,
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 4,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Número da visita:',
    startX + tableLimit - tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    `${NumeroVisita}ª visita`,
    startX + tableLimit - tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 4, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 5, tableLimit, line, 'F')
}

function indicadoresQualidade(doc, relatorio, _ref2) {
  const {
    boxHeight,
    fontSize,
    line,
    gutterY,
    mediumFontSize,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
  } = _ref2

  var TipoOrdenha
  if (relatorio.TipoOrdenha) {
    if (relatorio.TipoOrdenhaManual) TipoOrdenha = 'Manual'
    if (relatorio.TipoOrdenhaBaldeAoPe) TipoOrdenha = 'Balde ao Pé'
    if (relatorio.TipoOrdenhaTransferidor) TipoOrdenha = 'Transferidor'
    if (relatorio.TipoOrdenhaLinhaBaixa)
      TipoOrdenha = 'Circuito fechado / linha baixa'
    if (relatorio.TipoOrdenhaLinhaMedia)
      TipoOrdenha = 'Circuito fechado / linha média'
    if (relatorio.TipoOrdenhaLinhaAlta)
      TipoOrdenha = 'Circuito fechado / linha alta'
  }

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Principais Indicadores', startX, startY + 10)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  doc.rect(startX, startY + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Produção média (l/dia):',
    startX + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    relatorio.ProducaoMedia ? `${relatorio.ProducaoMedia}` : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit / 3, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Proteína média:',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    relatorio.ProteinaMediaUltimoMes
      ? `${relatorio.ProteinaMediaUltimoMes} %`
      : 'Não informado',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    startY + boxHeight,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Gordura média:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    relatorio.GorduraMediaUltimoMes
      ? relatorio.GorduraMediaUltimoMes + ' %'
      : 'Não informado',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 2, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.text(
    'CPP média:',
    startX + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    relatorio.CppMedia ? `${relatorio.CppMedia} UFC/ml * 10³` : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'CCS média',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    relatorio.CcsMedia
      ? `${relatorio.CcsMedia} céls/ml * 10³`
      : 'Não informado',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 3, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX + tableLimit, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.text(
    'Porcentagem de conformidades: ',
    startX + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    relatorio.conformPercentege ? relatorio.conformPercentege : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Porcentagem de não conformidades: ',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    relatorio.notConformPercentege
      ? relatorio.notConformPercentege
      : 'Não informado',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(startX, startY + boxHeight * 4, tableLimit, line, 'F') //superior

  doc.rect(startX, startY + boxHeight * 4, line, boxHeight, 'F') //esquerda

  doc.rect(startX + tableLimit, startY + boxHeight * 4, line, boxHeight, 'F') //direita

  doc.rect(startX, startY + boxHeight * 5, tableLimit, line, 'F') //inferior

  doc.text(
    'Tipo de ordenha:',
    startX + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    TipoOrdenha ? TipoOrdenha : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )
}

function composicaoRebanho(doc, relatorio, config, cursor) {
  const {
    boxHeight,
    fontSize,
    line,
    gutterY,
    mediumFontSize,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
  } = config

  cursor = startY + boxHeight + 180

  cursor = checkAddPage(doc, cursor, boxHeight, config)

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Composição do rebanho', startX, startY + 10)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  doc.rect(startX, cursor + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, cursor + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Vacas em lactação:',
    startX + smallGutterX,
    cursor + boxHeight + smallGutterY,
  )

  doc.text(
    `${relatorio.VacasLactacao}`,
    startX + smallGutterX,
    cursor + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit / 3, cursor + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Vacas secas:',
    startX + tableLimit / 3 + smallGutterX,
    cursor + boxHeight + smallGutterY,
  )

  doc.text(
    `${relatorio.VacasSecas}`,
    startX + tableLimit / 3 + smallGutterX,
    cursor + boxHeight + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    cursor + boxHeight,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Novilhas:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    cursor + boxHeight + smallGutterY,
  )

  doc.text(
    `${relatorio.Novilhas}`,
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    cursor + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit, cursor + boxHeight, line, boxHeight, 'F')

  doc.rect(startX, cursor + boxHeight * 2, tableLimit, line, 'F')

  doc.rect(startX, cursor + boxHeight * 2, line, boxHeight, 'F')

  doc.text(
    'Machos:',
    startX + smallGutterX,
    cursor + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    `${relatorio.Machos}`,
    startX + smallGutterX,
    cursor + boxHeight * 2 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 3,
    cursor + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Animais em recria:',
    startX + tableLimit / 3 + smallGutterX,
    cursor + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    `${relatorio.Recria}`,
    startX + tableLimit / 3 + smallGutterX,
    cursor + boxHeight * 2 + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    cursor + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Animais em aleitamento:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    cursor + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    `${relatorio.Aleitamento}`,
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    cursor + boxHeight * 2 + gutterY,
  )

  doc.rect(startX + tableLimit, cursor + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX, cursor + boxHeight * 3, tableLimit, line, 'F')

  doc.rect(startX, cursor + boxHeight * 3, line, boxHeight, 'F')

  doc.text(
    'Vacas em lactação / total de vacas:',
    startX + smallGutterX,
    cursor + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    `${utilsRelatorio.calcLacVacas(relatorio)}`,
    startX + smallGutterX,
    cursor + boxHeight * 3 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    cursor + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Vacas em lactação / total do rebanho:',
    startX + tableLimit / 2 + smallGutterX,
    cursor + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    `${utilsRelatorio.calcLacRebanho(relatorio)}`,
    startX + tableLimit / 2 + smallGutterX,
    cursor + boxHeight * 3 + gutterY,
  )

  doc.rect(startX + tableLimit, cursor + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX, cursor + boxHeight * 4, tableLimit, line, 'F')

  doc.rect(startX, cursor + boxHeight * 4, line, boxHeight, 'F')

  doc.text(
    'Produtividade / vacas em lactação:',
    startX + smallGutterX,
    cursor + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    `${utilsRelatorio.calcProdutividade(relatorio)}`,
    startX + smallGutterX,
    cursor + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    cursor + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Produtividade / total de vacas:',
    startX + tableLimit / 2 + smallGutterX,
    cursor + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    `${utilsRelatorio.calcProdutividadeTotal(relatorio)}`,
    startX + tableLimit / 2 + smallGutterX,
    cursor + boxHeight * 4 + gutterY,
  )

  doc.rect(startX + tableLimit, cursor + boxHeight * 4, line, boxHeight, 'F')

  doc.rect(startX, cursor + boxHeight * 5, tableLimit, line, 'F')

  cursor += cursor + boxHeight * 5

  return cursor
}

function ambientePreOrdenha(doc, relatorio, config, cursor) {
  const {
    boxHeight,
    fontSize,
    mediumFontSize,
    startX,
    smallBoxHeight,
    heightLimit,
    margin,
    tableLimit,
    line,
  } = config

  cursor += config.boxHeight + 30

  if (cursor + boxHeight * 4 > heightLimit) {
    doc.addPage()
    cursor = margin + 10
  }

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Ambiente (pré-ordenha)', startX, cursor)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  cursor += smallBoxHeight

  cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

  if (relatorio.UberesETetosLimpos == 'confirm') {
    renderCheckbox(
      doc,
      '(V) Úberes e tetos limpos antes da ordenha',
      cursor,
      config,
    )
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
  } else {
    renderCheckbox(
      doc,
      '(X) Úberes e tetos limpos antes da ordenha',
      cursor,
      config,
    )
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

    renderCheckboxList(doc, 'Recomendações:', cursor, config)

    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    if (relatorio.UberesETetosLimposRecomendacao1) {
      renderCheckboxList(
        doc,
        '  Manter limpas as áreas de descanso das vacas',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    if (relatorio.UberesETetosLimposRecomendacao2) {
      renderCheckboxList(
        doc,
        '  Cercar as áreas de descanso com acúmulo de barro',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    if (relatorio.UberesETetosLimposRecomendacao3) {
      renderCheckboxList(
        doc,
        '  Melhorar a higienização da sala de ordenha e anexos',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    if (relatorio.UberesETetosLimposRecomendacao4) {
      renderCheckboxList(
        doc,
        '  Realizar pré-dipping de forma correta e eficiente',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    doc.rect(startX, cursor, tableLimit, line, 'F')
  }

  if (relatorio.SalaOrdenhaEsperaLeiteLimpa == 'confirm') {
    renderCheckbox(
      doc,
      '(V) Sala de ordenha, sala de espera e sala de leite totalmente limpas',
      cursor,
      config,
    )
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
  } else {
    renderCheckbox(
      doc,
      '(X) Sala de ordenha, sala de espera e sala de leite totalmente limpas',
      cursor,
      config,
    )
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

    if (
      relatorio.HigienizacaoTanqueDeLeite ||
      relatorio.HigienizacaoLatoesDeLeite ||
      relatorio.HigienizacaoBaldes ||
      relatorio.HigienizacaoDemaisUtensilios
    ) {
      renderCheckboxList(
        doc,
        'Melhorar a higienização dos seguintes equipamentos:',
        cursor,
        config,
      )

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.HigienizacaoTanqueDeLeite) {
        renderCheckboxList(doc, '  Tanque de leite', cursor, config)
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.HigienizacaoLatoesDeLeite) {
        renderCheckboxList(doc, '  Latões de leite', cursor, config)
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.HigienizacaoBaldes) {
        renderCheckboxList(doc, '  Baldes', cursor, config)
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.HigienizacaoDemaisUtensilios) {
        renderCheckboxList(doc, '  Demais utensílios', cursor, config)
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
    }
    if (relatorio.SalaOrdenhaEsperaLeiteRecomendacao) {
      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      renderCheckboxList(
        doc,
        '  Melhorar a higienização da sala de ordenha e anexos',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    doc.rect(startX, cursor, tableLimit, line, 'F')
  }

  if (relatorio.RealizarLimpezaEquipamentos == 'confirm') {
    renderCheckbox(
      doc,
      '(V) Tanque de expansão, latas e demais utensílios que fazem contato direto com o leite estão limpos e higienizados',
      cursor,
      config,
    )
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
  } else {
    renderCheckbox(doc, '', cursor, config)
    renderCheckbox(
      doc,
      '(X) Tanque de expansão, latas e demais utensílios que fazem contato direto com o leite estão limpos e higienizados',
      cursor,
      config,
    )
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

    if (
      relatorio.RealizarLimpezaTanqueDeLeite ||
      relatorio.RealizarLimpezaLatoesDeLeite ||
      relatorio.RealizarLimpezaBaldes ||
      relatorio.RealizarLimpezaDemaisUtensilios
    ) {
      renderCheckboxList(
        doc,
        'Melhorar a higienização dos seguintes equipamentos:',
        cursor,
        config,
      )

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.RealizarLimpezaTanqueDeLeite) {
        renderCheckboxList(doc, '  Tanque de Leite', cursor, config)
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.RealizarLimpezaLatoesDeLeite) {
        renderCheckboxList(doc, '  Latões de Leite', cursor, config)
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.RealizarLimpezaBaldes) {
        renderCheckboxList(doc, '  Baldes', cursor, config)
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.RealizarLimpezaDemaisUtensilios) {
        renderCheckboxList(doc, '  Demais utensílios', cursor, config)
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
    }
    if (relatorio.RealizarLimpezaRecomendacao) {
      renderCheckboxList(doc, 'Recomendações:', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(
        doc,
        '  Melhorar a higienização da sala de ordenha e anexos',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    doc.rect(startX, cursor, tableLimit, line, 'F')
  }

  cursor += smallBoxHeight
  return cursor
}

function rotinaOrdenha(doc, relatorio, config, cursor) {
  const {
    boxHeight,
    fontSize,
    mediumFontSize,
    startX,
    smallBoxHeight,
    heightLimit,
    margin,
    tableLimit,
    line,
  } = config

  cursor += config.boxHeight

  if (cursor + boxHeight * 6 > heightLimit) {
    doc.addPage()
    cursor = margin
  }

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Rotina de ordenha', startX, cursor)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  cursor += smallBoxHeight

  cursor = checkAddPage(doc, cursor, smallBoxHeight * 2, config)

  if (relatorio.TesteDaCaneca == 'confirm') {
    renderCheckbox(
      doc,
      '(V) Teste da caneca retirando os 3 primeiros jatos',
      cursor,
      config,
    )
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight * 2, config)
  } else {
    renderCheckbox(
      doc,
      '(X) Teste da caneca retirando os 3 primeiros jatos',
      cursor,
      config,
    )
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight * 2, config)
    if (relatorio.TesteDaCanecaRecomendacao1) {
      renderCheckboxList(doc, 'Recomendações:', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight * 2, config)
      renderCheckboxList(
        doc,
        '  Realizar o teste da caneca retirando os 3 primeiros jatos',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight * 2, config)
    }
  }

  if (relatorio.PreDipping == 'confirm') {
    renderCheckbox(
      doc,
      '(V) Realização do pré-dipping de forma correta e eficiente',
      cursor,
      config,
    )
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight * 2, config)
  } else {
    renderCheckbox(doc, '', cursor, config)
    renderCheckbox(
      doc,
      '(X) Realização do pré-dipping de forma correta e eficiente',
      cursor,
      config,
    )
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)
    if (
      relatorio.PreDippingRecomendacao1 ||
      relatorio.PreDippingRecomendacao2
    ) {
      renderCheckboxList(doc, 'Recomendações:', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight * 2, config)
      if (relatorio.PreDippingRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Melhorar a limpeza e desinfecção dos tetos antes de ordenhar',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight * 2, config)
      }
      if (relatorio.PreDippingRecomendacao2) {
        renderCheckboxList(
          doc,
          '  Realizar pré-dipping de forma correta e eficiente',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight * 2, config)
      }
    }
  }

  if (relatorio.SecagemPapelToalha == 'confirm') {
    renderCheckbox(
      doc,
      '(V) Secagem com papel toalha eficiente, secando e limpando todos os tetos e esfincteres',
      cursor,
      config,
    )
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight * 2, config)
  } else {
    renderCheckbox(doc, '', cursor, config)
    renderCheckbox(
      doc,
      '(X) Secagem com papel toalha eficiente, secando e limpando todos os tetos e esfincteres',
      cursor,
      config,
    )
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight * 2, config)
    if (
      relatorio.SecagemPapelToalhaRecomendacao1 ||
      relatorio.SecagemPapelToalhaRecomendacao2
    ) {
      renderCheckboxList(doc, 'Recomendações:', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight * 2, config)
      if (relatorio.SecagemPapelToalhaRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Melhorar a limpeza e desinfecção dos tetos antes de ordenhar',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight * 2, config)
      }
      if (relatorio.SecagemPapelToalhaRecomendacao2) {
        renderCheckboxList(
          doc,
          '  Secar e limpar todos os tetos com papel toalha (1 folha por teto pelo menos)',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        doc.rect(startX, cursor, tableLimit, line, 'F')
        cursor = checkAddPage(doc, cursor, smallBoxHeight * 2, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }
  }

  if (relatorio.TetosEsfincterLimpo == 'confirm') {
    renderCheckbox(
      doc,
      '(V) Tetos e esfincteres totalmente limpos antes da colocação das teteiras',
      cursor,
      config,
    )
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight * 2, config)
  } else {
    renderCheckbox(
      doc,
      '(X) Tetos e esfincteres totalmente limpos antes da colocação das teteiras',
      cursor,
      config,
    )
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight * 2, config)
    if (relatorio.TetosEsfincterRecomendacao1) {
      renderCheckboxList(doc, 'Recomendações:', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight * 2, config)
      if (relatorio.TetosEsfincterRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Melhorar a limpeza e desinfecção dos tetos antes de ordenhar',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight * 2, config)
      }
    }
  }

  if (relatorio.SepararAnimaisComMastite == 'confirm') {
    renderCheckbox(
      doc,
      '(V) Separação de animais com mastite clínica e/ou em tratamento',
      cursor,
      config,
    )
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
  } else {
    renderCheckbox(
      doc,
      '(X) Separação de animais com mastite clínica e/ou em tratamento',
      cursor,
      config,
    )
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    if (relatorio.SepararAnimaisComMastiteRecomendacao1) {
      renderCheckboxList(doc, 'Recomendações:', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.SepararAnimaisComMastiteRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Realizar a separação de animais com mastite clínica e/ou em tratamento',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
    }
    doc.rect(startX, cursor, tableLimit, line, 'F')
  }
  return cursor
}

function rotinaLimpeza(doc, relatorio, config, cursor) {
  const {
    boxHeight,
    fontSize,
    mediumFontSize,
    startX,
    smallBoxHeight,
    heightLimit,
    margin,
    tableLimit,
    line,
  } = config

  cursor += config.boxHeight

  if (cursor + boxHeight * 6 > heightLimit) {
    doc.addPage()
    cursor = margin + 10
  }

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Rotina de limpeza', startX, cursor)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  cursor += smallBoxHeight

  cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

  if (relatorio.UtensiliosBasicosLimpeza == 'confirm') {
    renderCheckbox(
      doc,
      '(V) Utensílios e produtos básicos para correta limpeza dos equipamentos de ordenha',
      cursor,
      config,
    )
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
  } else {
    renderCheckbox(
      doc,
      '(X) Utensílios e produtos básicos para correta limpeza dos equipamentos de ordenha',
      cursor,
      config,
    )
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    renderCheckboxList(
      doc,
      'Providenciar aquisição dos seguintes itens para correta limpeza dos equipamentos:',
      cursor,
      config,
    )
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    if (relatorio.UtensilioBasicoDetergenteAlcalino) {
      renderCheckboxList(doc, '  Detergente alcalino clorado', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    if (relatorio.UtensilioBasicoDetergenteAcido) {
      renderCheckboxList(doc, '  Detergente ácido', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    if (relatorio.UtensilioBasicoDetergenteNeutro) {
      renderCheckboxList(doc, '  Detergente neutro', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    if (relatorio.UtensilioBasicoTermometro) {
      renderCheckboxList(doc, '  Termômetro', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    if (relatorio.UtensilioBasicoBucha) {
      renderCheckboxList(doc, '  Bucha', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    if (relatorio.UtensilioBasicoAquecedor) {
      renderCheckboxList(doc, '  Aquecedor', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    if (relatorio.UtensilioBasicoSanitizante) {
      renderCheckboxList(doc, '  Sanitizante', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    doc.rect(startX, cursor, tableLimit, line, 'F')
  }

  if (relatorio.EnxagueOrdenha == 'confirm') {
    renderCheckbox(
      doc,
      '(V) Água morna (35°C a 45°C) para enxague pós ordenha',
      cursor,
      config,
    )
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
  } else {
    renderCheckbox(
      doc,
      '(X) Água morna (35°C a 45°C) para enxague pós ordenha',
      cursor,
      config,
    )

    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    renderCheckboxList(doc, 'Recomendações:', cursor, config)
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

    if (relatorio.EnxagueOrdenhaRecomendacao1) {
      renderCheckboxList(
        doc,
        '  Realizar enxague da ordenha com água morna a 35ºC',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    doc.rect(startX, cursor, tableLimit, line, 'F')
  }

  if (relatorio.AguaEDiluicaoCorretaDetergenteAlcalino == 'confirm') {
    renderCheckbox(
      doc,
      '(V) Água a 75°C e diluição correta do detergente alcalino clorado',
      cursor,
      config,
    )
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
  } else {
    renderCheckbox(
      doc,
      '(X) Água a 75°C e diluição correta do detergente alcalino clorado',
      cursor,
      config,
    )

    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    renderCheckboxList(doc, 'Recomendações:', cursor, config)
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

    if (relatorio.AguaEDiluicaoDetergenteAlcalinoRecomendacao1) {
      renderCheckboxList(
        doc,
        '  Aquecer a água para lavagem do equipamento a 75ºC',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    if (relatorio.AguaEDiluicaoDetergenteAlcalinoRecomendacao2) {
      renderCheckboxList(
        doc,
        '  Corrigir a dosagem do detergente alcalino clorado para o volume de água utilizado (ml por lavagem)',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      doc.rect(startX, cursor, tableLimit, line, 'F')
      cursor = checkAddPage(doc, cursor, smallBoxHeight * 3, config)
    }
    doc.rect(startX, cursor, tableLimit, line, 'F')
  }

  if (relatorio.SolucaoAguaAposLavagem == 'confirm') {
    renderCheckbox(
      doc,
      '(V) Solução água + alcalino a 40°C (mínimo) após os 10 minutos de lavagem com alcalino clorado',
      cursor,
      config,
    )
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
  } else {
    renderCheckbox(
      doc,
      '(X) Solução água + alcalino a 40°C (mínimo) após os 10 minutos de lavagem com alcalino clorado',
      cursor,
      config,
    )

    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    renderCheckboxList(doc, 'Recomendações:', cursor, config)
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

    if (relatorio.SolucaoAguaAposLavagemRecomendacao1) {
      renderCheckboxList(
        doc,
        '  Deixar a máquina batendo por 8 a 10 minutos',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      doc.rect(startX, cursor, tableLimit, line, 'F')
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    doc.rect(startX, cursor, tableLimit, line, 'F')
  }

  if (relatorio.EnxagueImediatoAposLavagem == 'confirm') {
    renderCheckbox(
      doc,
      '(V) Enxágue imediato após a lavagem com detergente alcalino',
      cursor,
      config,
    )
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
  } else {
    renderCheckbox(
      doc,
      '(X) Enxágue imediato após a lavagem com detergente alcalino',
      cursor,
      config,
    )
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    renderCheckboxList(doc, 'Recomendações:', cursor, config)
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    if (relatorio.EnxagueImediatoAposLavagemRecomendacao1) {
      renderCheckboxList(
        doc,
        '  Realizar o enxágue imediato após a lavagem com detergente alcalino',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    doc.rect(startX, cursor, tableLimit, line, 'F')
  }

  if (relatorio.AguaEDiluicaoCorretaDetergenteAcido == 'confirm') {
    renderCheckbox(
      doc,
      '(V) Temperatura da aguá e diluição correta do detergente ácido (lavagem 3x por semana ou conforme recomendação)',
      cursor,
      config,
    )
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
  } else {
    renderCheckbox(
      doc,
      '(X) Temperatura da aguá e diluição correta do detergente ácido (lavagem 3x por semana ou conforme recomendação)',
      cursor,
      config,
    )

    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    renderCheckboxList(doc, 'Recomendações:', cursor, config)
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

    if (relatorio.AguaEDiluicaoDetergenteAcidoRecomendacao1) {
      renderCheckboxList(
        doc,
        '  Lavagem do equipamento com detergente ácido 3x por semana ou conforme a recomendação do fabricante',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    doc.rect(startX, cursor, tableLimit, line, 'F')
  }

  if (relatorio.OrdenhaBaldeaoPeCorreta == 'confirm') {
    renderCheckbox(
      doc,
      '(V) Frequência correta de lavagem da linha de vácuo ( 1-2x por semana)',
      cursor,
      config,
    )
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
  } else {
    renderCheckbox(
      doc,
      '(X) Frequência correta de lavagem da linha de vácuo ( 1-2x por semana)',
      cursor,
      config,
    )

    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    renderCheckboxList(doc, 'Recomendações:', cursor, config)
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

    if (relatorio.OrdenhaBaldeaoPeCorretaRecomendacao1) {
      renderCheckboxList(
        doc,
        '  Realizar a lavagem da linha de vácuo pelo menos 1x por semana',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    doc.rect(startX, cursor, tableLimit, line, 'F')
  }

  if (relatorio.OrdenhaBaldeaoPeTempo == 'confirm') {
    renderCheckbox(
      doc,
      '(V) Tempo de permanência do leite na lata inferior a 15 minutos',
      cursor,
      config,
    )
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
  } else {
    renderCheckbox(
      doc,
      '(X) Tempo de permanência do leite na lata inferior a 15 minutos',
      cursor,
      config,
    )

    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    renderCheckboxList(doc, 'Recomendações:', cursor, config)
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

    if (relatorio.OrdenhaBaldeaoPeTempoRecomendacao1) {
      renderCheckboxList(
        doc,
        '  Não deixar o leite na lata mais que 15 minutos sem refrigeração',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    doc.rect(startX, cursor, tableLimit, line, 'F')
  }

  doc.rect(startX, cursor, tableLimit, line, 'F')

  cursor += smallBoxHeight

  return cursor
}

function funcionamentoTanque(doc, relatorio, config, cursor) {
  const {
    boxHeight,
    fontSize,
    mediumFontSize,
    startX,
    smallBoxHeight,
    heightLimit,
    margin,
    tableLimit,
    line,
  } = config

  cursor += config.boxHeight

  if (cursor + boxHeight * 6 > heightLimit) {
    doc.addPage()
    cursor = margin
  }

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Funcionamento de tanque', startX, cursor)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  cursor += smallBoxHeight

  cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

  if (relatorio.FuncionamentoTanque == 'confirm') {
    renderCheckbox(
      doc,
      '(V) Tanque de expansão em funcionamento normal (temperatura do leite condizente com a mostrada no visor do tanque)',
      cursor,
      config,
    )
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
  } else {
    renderCheckbox(
      doc,
      '(X) Tanque de expansão em funcionamento normal (temperatura do leite condizente com a mostrada no visor do tanque)',
      cursor,
      config,
    )

    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    renderCheckboxList(doc, 'Recomendações: ', cursor, config)
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

    if (relatorio.FuncionamentoTanqueRecomendacao1) {
      renderCheckboxList(
        doc,
        '  Solicitar a visita de um técnico para aferição da temperatura e regulagem/conserto do tanque de refrigeração',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    doc.rect(startX, cursor, tableLimit, line, 'F')
  }

  if (relatorio.TemperaturaLeite == 'confirm') {
    renderCheckbox(
      doc,
      '(V) Leite abaixo de 4°C até 2 horas após a ordenha',
      cursor,
      config,
    )
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
  } else {
    renderCheckbox(
      doc,
      '(X) Leite abaixo de 4°C até 2 horas após a ordenha',
      cursor,
      config,
    )

    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    renderCheckboxList(doc, 'Recomendações: ', cursor, config)
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

    if (relatorio.TemperaturaLeiteRecomendacao1) {
      renderCheckboxList(
        doc,
        '  Solicitar a visita de um técnico para aferição da temperatura e regulagem/conserto do tanque de refrigeração.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    doc.rect(startX, cursor, tableLimit, line, 'F')
  }

  if (relatorio.ColetaLeiteIntervalo == 'confirm') {
    renderCheckbox(
      doc,
      '(V) Coleta do leite de, no máximo, 48 horas de intervalo',
      cursor,
      config,
    )
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
  } else {
    renderCheckbox(
      doc,
      '(X) Coleta do leite de, no máximo, 48 horas de intervalo',
      cursor,
      config,
    )
    cursor += smallBoxHeight
  }

  if (relatorio.TanqueCompartilhado == 'confirm') {
    renderCheckbox(doc, '(V) O Tanque é compartilhado', cursor, config)
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
  } else {
    renderCheckbox(doc, '(X) O Tanque é compartilhado', cursor, config)
    cursor += smallBoxHeight
  }
  return cursor
}

function ListDasPrincipaisConformidades(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    line,
    startX,
    tableLimit,
    margin,
    heightLimit,
    boxHeight,
    mediumFontSize,
    fontSize,
  } = config

  cursor += config.boxHeight

  if (cursor + boxHeight * 6 > heightLimit) {
    doc.addPage()
    cursor = margin
  }

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Listagem das principais inconformidades', startX, cursor)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  cursor += smallBoxHeight

  cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

  if (
    relatorio.step_8_ambiental_de_descanso ||
    relatorio.step_8_sala_de_ordenha ||
    relatorio.step_8_limpeza_do_equipamento ||
    relatorio.step_8_desregulagem_do_tanque ||
    relatorio.step_8_tanque_de_resfriamento ||
    relatorio.step_8_coleta_do_leite_superior_a_48_horas ||
    relatorio.step_8_atraso_no_resfriamento_do_leite
  ) {
    doc.rect(startX, cursor, tableLimit, line, 'F')
    doc.setFont('helvetica', 'bold')
    renderCheckboxList(doc, ' • Inconformidades', cursor, config)
    doc.setFont('helvetica', 'normal')
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

    if (relatorio.step_8_ambiental_de_descanso) {
      renderCheckboxList(
        doc,
        'Ambiente de descanso dos animais com acúmulo de barro',
        cursor,
        config,
      )
      cursor += smallBoxHeight
    }

    if (relatorio.step_8_sala_de_ordenha) {
      renderCheckboxList(
        doc,
        'Sala de ordenha sem higienização adequada',
        cursor,
        config,
      )
      cursor += smallBoxHeight
    }

    if (relatorio.step_8_limpeza_do_equipamento) {
      renderCheckboxList(
        doc,
        'Limpeza do equipamento de ordenha inadequada',
        cursor,
        config,
      )
      cursor += smallBoxHeight
    }

    if (relatorio.step_8_desregulagem_do_tanque) {
      renderCheckboxList(
        doc,
        'Desregulagem do tanque de resfriamento (termostato)',
        cursor,
        config,
      )
      cursor += smallBoxHeight
    }

    if (relatorio.step_8_tanque_de_resfriamento) {
      renderCheckboxList(
        doc,
        'Tanque não refrigera o leite a 4 ºC em duas horas',
        cursor,
        config,
      )
      cursor += smallBoxHeight
    }

    if (relatorio.step_8_coleta_do_leite_superior_a_48_horas) {
      renderCheckboxList(
        doc,
        'Coleta do leite superior a 48 horas',
        cursor,
        config,
      )
      cursor += smallBoxHeight
    }

    if (relatorio.step_8_atraso_no_resfriamento_do_leite) {
      renderCheckboxList(
        doc,
        'Atraso no resfriamento do leite (Balde ao pé, Ordenha manual)',
        cursor,
        config,
      )
      cursor += smallBoxHeight
    }

    doc.rect(startX, cursor, tableLimit, line, 'F')
  }

  doc.rect(startX, cursor, tableLimit, line, 'F')
}

function recomendacaoGeral(doc, relatorio, config, cursor) {
  const {
    boxHeight,
    fontSize,
    mediumFontSize,
    startX,
    smallBoxHeight,
    heightLimit,
    margin,
  } = config

  cursor += config.boxHeight

  if (cursor + boxHeight * 8 > heightLimit) {
    doc.addPage()
    cursor = margin + 10
  }
  if (
    relatorio.RecomendacaoGeralCPP &&
    relatorio.RecomendacaoGeralCPP.trim() !== ''
  ) {
    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Recomendação geral', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight

    cursor = renderRecomendacoes(
      doc,
      '',
      relatorio.RecomendacaoGeralCPP,
      cursor,
      config,
    )
    cursor += smallBoxHeight
  }
  return cursor
}

function fotosAnexo(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    mediumFontSize,
    fontSize,
    startX,
    tableLimit,
    heightLimit,
    margin,
  } = config

  if (
    (relatorio.ImagensAmbientePreOrdenha &&
      relatorio.ImagensAmbientePreOrdenha.length > 0) ||
    (relatorio.ImagensRotinaDeOrdenha &&
      relatorio.ImagensRotinaDeOrdenha.length > 0) ||
    (relatorio.ImagensRotinaDeLimpeza &&
      relatorio.ImagensRotinaDeLimpeza.length > 0) ||
    (relatorio.ImagensFuncionamentoTanque &&
      relatorio.ImagensFuncionamentoTanque.length > 0)
  ) {
    cursor += smallBoxHeight
    if (cursor + boxHeight * 8 > heightLimit) {
      doc.addPage()
      cursor = margin + 10
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Imagens em anexo', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight

    var colInicio = startX

    if (
      relatorio.ImagensAmbientePreOrdenha &&
      relatorio.ImagensAmbientePreOrdenha.length > 0
    ) {
      if (cursor + boxHeight * 8 > heightLimit) {
        doc.addPage()
        cursor = margin + 10
      }

      doc
        .setFont('helvetica', 'bold')
        .text('Imagens ambiente (pré-ordenha)', startX, cursor)
        .setFont('helvetica', 'normal')

      cursor += smallBoxHeight

      let imagem = new Image()
      Object.keys(relatorio.ImagensAmbientePreOrdenha).forEach(item => {
        try {
          if (item % 2 === 1) {
            colInicio = startX + 10 + tableLimit / 2
          } else {
            colInicio = startX + 40
          }

          if (cursor + boxHeight * 11 > heightLimit) {
            doc.addPage()
            cursor = margin + 10
          }
          imagem.src = `${ENV_APP.apiUri}/download-image/${relatorio.ImagensAmbientePreOrdenha[item].id}`

          doc.addImage(imagem, 'PNG', colInicio, cursor, 180, 180, '', 'FAST')

          if (
            item % 2 === 1 ||
            Object.keys(relatorio.ImagensAmbientePreOrdenha).length - 1 == item
          ) {
            cursor += 200
          }
          // eslint-disable-next-line no-empty
        } catch (error) {}
      })
      cursor += boxHeight //cursor += 380
    }

    colInicio = startX

    if (
      relatorio.ImagensRotinaDeOrdenha &&
      relatorio.ImagensRotinaDeOrdenha.length > 0
    ) {
      if (cursor + boxHeight * 8 > heightLimit) {
        doc.addPage()
        cursor = margin
      }

      doc
        .setFont('helvetica', 'bold')
        .text('Imagens rotina de ordenha', startX, cursor)
        .setFont('helvetica', 'normal')

      cursor += smallBoxHeight

      let imagem = new Image()
      Object.keys(relatorio.ImagensRotinaDeOrdenha).forEach(item => {
        try {
          if (item % 2 === 1) {
            colInicio = startX + 10 + tableLimit / 2
          } else {
            colInicio = startX + 40
          }

          if (cursor + boxHeight * 11 > heightLimit) {
            doc.addPage()
            cursor = margin
          }
          imagem.src = `${ENV_APP.apiUri}/download-image/${relatorio.ImagensRotinaDeOrdenha[item].id}`

          doc.addImage(imagem, 'PNG', colInicio, cursor, 180, 180, '', 'FAST')

          if (
            item % 2 === 1 ||
            Object.keys(relatorio.ImagensRotinaDeOrdenha).length - 1 == item
          ) {
            cursor += 200
          }
          // eslint-disable-next-line no-empty
        } catch (error) {}
      })
      cursor += boxHeight //cursor += 380
    }

    colInicio = startX

    if (
      relatorio.ImagensRotinaDeLimpeza &&
      relatorio.ImagensRotinaDeLimpeza.length > 0
    ) {
      if (cursor + boxHeight * 8 > heightLimit) {
        doc.addPage()
        cursor = margin
      }

      doc
        .setFont('helvetica', 'bold')
        .text('Imagens rotina de limpeza', startX, cursor)
        .setFont('helvetica', 'normal')

      cursor += smallBoxHeight

      let imagem = new Image()
      Object.keys(relatorio.ImagensRotinaDeLimpeza).forEach(item => {
        try {
          if (item % 2 === 1) {
            colInicio = startX + 10 + tableLimit / 2
          } else {
            colInicio = startX + 40
          }

          if (cursor + boxHeight * 11 > heightLimit) {
            doc.addPage()
            cursor = margin
          }
          imagem.src = `${ENV_APP.apiUri}/download-image/${relatorio.ImagensRotinaDeLimpeza[item].id}`

          doc.addImage(imagem, 'PNG', colInicio, cursor, 180, 180, '', 'FAST')

          if (
            item % 2 === 1 ||
            Object.keys(relatorio.ImagensRotinaDeLimpeza).length - 1 == item
          ) {
            cursor += 200
          }
          // eslint-disable-next-line no-empty
        } catch (error) {}
      })
      cursor += boxHeight //cursor += 380
    }

    colInicio = startX

    if (
      relatorio.ImagensFuncionamentoTanque &&
      relatorio.ImagensFuncionamentoTanque.length > 0
    ) {
      if (cursor + boxHeight * 8 > heightLimit) {
        doc.addPage()
        cursor = margin
      }

      doc
        .setFont('helvetica', 'bold')
        .text('Imagens do funcionamento do tanque', startX, cursor)
        .setFont('helvetica', 'normal')

      cursor += smallBoxHeight

      let imagem = new Image()
      Object.keys(relatorio.ImagensFuncionamentoTanque).forEach(item => {
        try {
          if (item % 2 === 1) {
            colInicio = startX + 10 + tableLimit / 2
          } else {
            colInicio = startX + 40
          }

          if (cursor + boxHeight * 11 > heightLimit) {
            doc.addPage()
            cursor = margin
          }
          imagem.src = `${ENV_APP.apiUri}/download-image/${relatorio.ImagensFuncionamentoTanque[item].id}`

          doc.addImage(imagem, 'PNG', colInicio, cursor, 180, 180, '', 'FAST')

          if (
            item % 2 === 1 ||
            Object.keys(relatorio.ImagensFuncionamentoTanque).length - 1 == item
          ) {
            cursor += 200
          }
          // eslint-disable-next-line no-empty
        } catch (error) {}
      })
      cursor += boxHeight //cursor += 380
    }
  }

  return cursor
}

function proximaVisitaAssinaturas(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    tableLimit,
    startX,
    smallGutterY,
    line,
    heightLimit,
    margin,
  } = config

  cursor += config.boxHeight

  if (cursor + boxHeight * 8 > heightLimit) {
    doc.addPage()
    cursor = margin
  }

  const proximaVisita = relatorio.DataProximaVisita
    ? relatorio.DataProximaVisita
    : '-'

  doc.text('Data da próxima visita', tableLimit / 2 - 30, cursor + boxHeight)
  doc.text(
    proximaVisita,
    tableLimit / 2 - 5,
    cursor + boxHeight + smallBoxHeight,
  )

  cursor += boxHeight * 2

  const produtor = new Image()

  if (relatorio.AssinaturaProdutorOuResponsavel === '1') {
    produtor.src = relatorio.AssinaturaProdutor
    doc.addImage(
      produtor,
      'PNG',
      startX + 50,
      cursor - 140,
      100,
      145,
      '',
      'FAST',
      270,
    )
  } else {
    produtor.src = relatorio.AssinaturaResponsavel
    doc.addImage(
      produtor,
      'PNG',
      startX + 50,
      cursor - 140,
      100,
      145,
      '',
      'FAST',
      270,
    )
  }

  const consultor = new Image()
  consultor.src = relatorio.AssinaturaConsultor

  if (relatorio.AssinaturaConsultor) {
    doc.addImage(
      consultor,
      'PNG',
      tableLimit - 180,
      cursor - 140,
      100,
      145,
      '',
      'FAST',
      270,
    )
  }

  doc.rect(startX, cursor + 100, tableLimit / 2.1, line, 'F')

  if (relatorio.AssinaturaProdutorOuResponsavel === '1') {
    doc.text(
      relatorio.fazenda.produtor.Nome,
      tableLimit / 3.5,
      cursor + 100 + smallGutterY,
      null,
      null,
      'center',
    )
    doc.text(
      'Assinatura produtor(a)',
      tableLimit / 3.5,
      cursor + 115 + smallGutterY,
      null,
      null,
      'center',
    )
  } else {
    doc.text(
      relatorio.NomeResponsavel,
      tableLimit / 3.5,
      cursor + 100 + smallGutterY,
      null,
      null,
      'center',
    )
    doc.text(
      'Assinatura do responsável',
      tableLimit / 3.5,
      cursor + 115 + smallGutterY,
      null,
      null,
      'center',
    )
  }

  doc.rect(startX + 280, cursor + 100, tableLimit / 2.1, line, 'F')
  doc.text(
    relatorio.consultor.pessoafisica.Nome,
    startX + 420,
    cursor + 100 + smallGutterY,
    null,
    null,
    'center',
  )
  doc.text(
    'Assinatura consultor(a)',
    startX + 420,
    cursor + 115 + smallGutterY,
    null,
    null,
    'center',
  )

  cursor += boxHeight * 2

  return cursor
}

function rodapeImpressao(doc, config, cursor, text) {
  const { boxHeight, heightLimit, margin } = config
  const docWidth = doc.internal.pageSize.width
  const texto =
    utilsRelatorio.getDataAtual() +
    '       ' +
    `${ENV_APP.relUri}` +
    '        Pag. ' +
    text

  if (cursor + boxHeight * 2 > heightLimit) {
    doc.addPage()
    cursor = margin
  }

  doc.text(texto, docWidth / 2, heightLimit + 7, 'center')
}

export default data => {
  return new Promise(function (resolve, reject) {
    const MARGIN = 25
    const realStartY = 95
    var doc = new JsPDF({
      format: 'a4',
      compress: true,
      unit: 'pt',
    })
    var config = {
      startY: realStartY,
      startX: MARGIN,
      smallGutterY: 12,
      smallGutterX: 6,
      line: 0.3,
      tableLimit: doc.internal.pageSize.width - MARGIN - MARGIN,
      heightLimit: doc.internal.pageSize.height - MARGIN,
      margin: MARGIN,
      lineColor: '#000',
      boxHeight: 27,
      smallBoxHeight: 18,
      gutterX: 14,
      gutterY: 23,
      smallFontSize: 6.5,
      fontSize: 10,
      largefontSize: 16,
      mediumFontSize: 12,
      logo: 'img/' + `${ENV_APP.imgFileLogin}`,
      bodyStarY: realStartY + 25 * 8.5,
    }

    try {
      HtmlToCanvas(document.querySelector('#chart-rebanho')).then(canvas => {
        var dataURL = canvas.toDataURL()
        doc.addImage(
          dataURL,
          'PNG',
          config.startX * 2.8,
          config.startY + config.boxHeight * 9 + 50,
          456,
          200,
        )
        var cursor = 0
        header(doc, config, data)
        subHeader(doc, data, config)
        config.startY = config.startY + config.boxHeight * 5 + 20
        indicadoresQualidade(doc, data, config)
        config.startY = config.startY + config.boxHeight * 4 + 50
        cursor = composicaoRebanho(doc, data, config, cursor)
        config.startY = cursor + config.boxHeight + 10
        cursor = ambientePreOrdenha(doc, data, config, cursor)
        config.startY = cursor + config.boxHeight - 3
        cursor = rotinaOrdenha(doc, data, config, cursor)
        config.startY = cursor + config.boxHeight - 3
        cursor = rotinaLimpeza(doc, data, config, cursor)
        config.startY = cursor + config.boxHeight - 3
        cursor = funcionamentoTanque(doc, data, config, cursor)
        config.startY = cursor + config.boxHeight - 3
        ListDasPrincipaisConformidades(doc, data, config, cursor)
        config.startY = cursor + config.boxHeight - 3
        cursor = recomendacaoGeral(doc, data, config, cursor)
        config.startY = cursor + config.boxHeight - 3
        cursor = fotosAnexo(doc, data, config, cursor)
        config.startY = cursor + config.boxHeight - 3
        cursor = proximaVisitaAssinaturas(doc, data, config, cursor)
        const pageCount = doc.internal.getNumberOfPages()

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i)
          rodapeImpressao(
            doc,
            config,
            cursor + config.boxHeight,
            String(i) + ' de ' + String(pageCount),
          )
        }
        doc
          .save(
            `relatorio-cpp-${data.NumeroVisita}-${data.fazenda.pessoajuridica.NomeFantasia}.pdf`,
            { returnPromise: true },
          )
          .then(() => {
            return resolve()
          })
      })
    } catch (err) {
      reject(err)
    }
  })
}
