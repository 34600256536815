<template>
  <v-card class="ma-4">
    <v-card-title style="text-align: left"
      >Funcionamento de Tanque</v-card-title
    >
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          v-if="relatorio.FuncionamentoTanque == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Tanque de expansão em funcionamento normal (temperatura do leite condizente com a mostrada no visor do tanque)'
            }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.TemperaturaLeite == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{ 'Leite abaixo de 4°C até 2 horas após a ordenha' }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.ColetaLeiteIntervalo == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{ 'Coleta do leite de, no máximo, 48 horas de intervalo' }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.TanqueCompartilhado == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{ 'O Tanque é compartilhado' }}
          </span>
        </v-col>

        <span class="pa-3"></span>

        <v-col
          v-if="relatorio.FuncionamentoTanque != 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Tanque de expansão em funcionamento normal (temperatura do leite condizente com a mostrada no visor do tanque)'
            }}
          </span>
          <v-col
            v-if="relatorio.FuncionamentoTanqueRecomendacao1"
            cols="12"
            class="col-bordered"
          >
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col cols="12" class="col-bordered">
              <span class="value-title">
                {{
                  'Solicitar a visita de um técnico para aferição da temperatura e regulagem/conserto do tanque de refrigeração.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>
        <v-col
          v-if="relatorio.TemperaturaLeite != 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'Leite abaixo de 4°C até 2 horas após a ordenha' }}
          </span>
          <v-col
            v-if="relatorio.TemperaturaLeiteRecomendacao1"
            cols="12"
            class="col-bordered"
          >
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col cols="12" class="col-bordered">
              <span class="value-title">
                {{
                  'Solicitar a visita de um técnico para aferição da temperatura e regulagem/conserto do tanque de refrigeração.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>
        <v-col
          v-if="relatorio.ColetaLeiteIntervalo != 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'Coleta do leite de, no máximo, 48 horas de intervalo' }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.TanqueCompartilhado != 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'O Tanque é compartilhado' }}
          </span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { convertNumberBR } from '../../../../utils/masks'
export default {
  name: 'IndicadoresQualidade',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    convertNumberBR,
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
