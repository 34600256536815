<template>
  <v-card class="ma-4">
    <v-card-title style="text-align: left">Rotina de ordenha</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          v-if="relatorio.TesteDaCaneca == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{ 'Teste da caneca retirando os 3 primeiros jatos' }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.PreDipping == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{ 'Realização do pré-dipping de forma correta e eficiente' }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.SecagemPapelToalha == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Secagem com papel toalha eficiente, secando e limpando todos os tetos e esfincteres'
            }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.TetosEsfincterLimpo == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Tetos e esfincteres totalmente limpos antes da colocação das teteiras'
            }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.SepararAnimaisComMastite == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{ 'Separação de animais com mastite clínica e/ou em tratamento' }}
          </span>
        </v-col>

        <span class="pa-3"></span>
        <v-col
          v-if="relatorio.TesteDaCaneca != 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'Teste da caneca retirando os 3 primeiros jatos' }}
          </span>
          <v-col
            v-if="relatorio.TesteDaCanecaRecomendacao1"
            cols="12"
            class="col-bordered"
          >
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col cols="12" class="col-bordered">
              <span class="value-title">
                {{
                  'Realizar o teste da caneca retirando os 3 primeiros jatos'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>
        <v-col
          v-if="relatorio.PreDipping != 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'Realização do pré-dipping de forma correta e eficiente' }}
          </span>
          <v-col
            v-if="relatorio.PreDippingRecomendacao1"
            cols="12"
            class="col-bordered"
          >
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col cols="12" class="col-bordered">
              <span class="value-title">
                {{
                  'Melhorar a limpeza e desinfecção dos tetos antes de ordenhar'
                }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.PreDippingRecomendacao2"
              cols="12"
              class="col-bordered"
            >
              <span class="value-title">
                {{ 'Realizar pré-dipping de forma correta e eficiente' }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>
        <v-col
          v-if="relatorio.SecagemPapelToalha != 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Secagem com papel toalha eficiente, secando e limpando todos os tetos e esfincteres'
            }}
          </span>
          <v-col
            v-if="relatorio.SecagemPapelToalhaRecomendacao1"
            cols="12"
            class="col-bordered"
          >
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col cols="12" class="col-bordered">
              <span class="value-title">
                {{
                  'Melhorar a limpeza e desinfecção dos tetos antes de ordenhar'
                }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.SecagemPapelToalhaRecomendacao2"
              cols="12"
              class="col-bordered"
            >
              <span class="value-title">
                {{
                  'Secar os tetos com papel toalha, secando e limpando todos os tetos (1 folha por teto pelo menos)'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>
        <v-col
          v-if="relatorio.TetosEsfincterLimpo != 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Tetos e esfincteres totalmente limpos antes da colocação das teteiras'
            }}
          </span>
          <v-col
            v-if="relatorio.TetosEsfincterRecomendacao1"
            cols="12"
            class="col-bordered"
          >
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col cols="12" class="col-bordered">
              <span class="value-title">
                {{
                  'Melhorar a limpeza e desinfecção dos tetos antes de ordenhar'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>
        <v-col
          v-if="relatorio.SepararAnimaisComMastite != 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'Separação de animais com mastite clínica e/ou em tratamento' }}
          </span>
          <v-col
            v-if="relatorio.SepararAnimaisComMastiteRecomendacao1"
            cols="12"
            class="col-bordered"
          >
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col cols="12" class="col-bordered">
              <span class="value-title">
                {{
                  'Realizar a separação de animais com mastite clínica e/ou em tratamento'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { convertNumberBR } from '../../../../utils/masks'
export default {
  name: 'IndicadoresQualidade',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    convertNumberBR,
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
