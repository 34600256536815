<template>
  <v-card class="ma-4">
    <v-card-title style="text-align: left"
      >Rotina de limpeza dos equipamentos</v-card-title
    >
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          v-if="
            relatorio.UtensiliosBasicosLimpeza == 'confirm' &&
            tipoDeOrdenha('UtensiliosBasicosLimpeza')
          "
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Utensílios e produtos básicos para correta limpeza dos equipamentos de ordenha (detergentes alcalino clorado, ácido e neutro, termômetro, bucha, aquecedor de água)'
            }}
          </span>
        </v-col>
        <v-col
          v-if="
            relatorio.EnxagueOrdenha == 'confirm' &&
            tipoDeOrdenha('EnxagueOrdenha')
          "
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{ 'Água morna (35°C a 45°C) para enxague pós ordenha' }}
          </span>
        </v-col>
        <v-col
          v-if="
            relatorio.AguaEDiluicaoCorretaDetergenteAlcalino == 'confirm' &&
            tipoDeOrdenha('AguaEDiluicaoCorretaDetergenteAlcalino')
          "
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Água a 75°C e diluição correta do detergente alcalino clorado'
            }}
          </span>
        </v-col>
        <v-col
          v-if="
            relatorio.SolucaoAguaAposLavagem == 'confirm' &&
            tipoDeOrdenha('SolucaoAguaAposLavagem')
          "
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Solução água + alcalino a 40°C (mínimo) após os 10 minutos de lavagem com alcalino clorado'
            }}
          </span>
        </v-col>
        <v-col
          v-if="
            relatorio.EnxagueImediatoAposLavagem == 'confirm' &&
            tipoDeOrdenha('EnxagueImediatoAposLavagem')
          "
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{ 'Enxágue imediato após a lavagem com detergente alcalino' }}
          </span>
        </v-col>
        <v-col
          v-if="
            relatorio.AguaEDiluicaoCorretaDetergenteAcido == 'confirm' &&
            tipoDeOrdenha('AguaEDiluicaoCorretaDetergenteAcido')
          "
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Temperatura da aguá e diluição correta do detergente ácido (lavagem 3x por semana ou conforme recomendação)'
            }}
          </span>
        </v-col>
        <v-col
          v-if="
            relatorio.OrdenhaBaldeaoPeCorreta == 'confirm' &&
            tipoDeOrdenha('OrdenhaBaldeaoPeCorreta')
          "
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Frequência correta de lavagem da linha de vácuo ( 1-2x por semana)'
            }}
          </span>
        </v-col>
        <v-col
          v-if="
            relatorio.OrdenhaBaldeaoPeTempo == 'confirm' &&
            tipoDeOrdenha('OrdenhaBaldeaoPeTempo')
          "
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{ 'Tempo de permanência do leite na lata inferior a 15 minutos' }}
          </span>
        </v-col>

        <v-col
          v-if="
            relatorio.UtensiliosBasicosLimpeza != 'confirm' &&
            tipoDeOrdenha('UtensiliosBasicosLimpeza')
          "
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Utensílios e produtos básicos para correta limpeza dos equipamentos de ordenha (detergentes alcalino clorado, ácido e neutro, termômetro, bucha, aquecedor de água)'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{
                'Providenciar aquisição dos seguintes itens para correta limpeza dos equipamentos:'
              }}
            </span>
            <v-col
              v-if="relatorio.UtensilioBasicoDetergenteAlcalino"
              cols="12"
              class="col-bordered"
            >
              <span class="value-title">
                {{ 'Detergente alcalino clorado' }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.UtensilioBasicoDetergenteAcido"
              cols="12"
              class="col-bordered"
            >
              <span class="value-title">
                {{ 'Detergente ácido' }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.UtensilioBasicoDetergenteNeutro"
              cols="12"
              class="col-bordered"
            >
              <span class="value-title">
                {{ 'Detergente neutro' }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.UtensilioBasicoTermometro"
              cols="12"
              class="col-bordered"
            >
              <span class="value-title">
                {{ 'Termômetro' }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.UtensilioBasicoBucha"
              cols="12"
              class="col-bordered"
            >
              <span class="value-title">
                {{ 'Bucha' }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.UtensilioBasicoAquecedor"
              cols="12"
              class="col-bordered"
            >
              <span class="value-title">
                {{ 'Aquecedor de água' }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.UtensilioBasicoSanitizante"
              cols="12"
              class="col-bordered"
            >
              <span class="value-title">
                {{ 'Sanitizante' }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>
        <v-col
          v-if="
            relatorio.EnxagueOrdenha != 'confirm' &&
            tipoDeOrdenha('EnxagueOrdenha')
          "
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'Água morna (35°C a 45°C) para enxague pós ordenha' }}
          </span>
          <v-col
            v-if="relatorio.EnxagueOrdenhaRecomendacao1"
            cols="12"
            class="col-bordered"
          >
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col cols="12" class="col-bordered">
              <span class="value-title">
                {{ 'Realizar enxague da ordenha com água morna a 35ºC' }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>
        <v-col
          v-if="
            relatorio.AguaEDiluicaoCorretaDetergenteAlcalino != 'confirm' &&
            tipoDeOrdenha('AguaEDiluicaoCorretaDetergenteAlcalino')
          "
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Água a 75°C e diluição correta do detergente alcalino clorado'
            }}
          </span>
          <v-col
            v-if="relatorio.AguaEDiluicaoDetergenteAlcalinoRecomendacao1"
            cols="12"
            class="col-bordered"
          >
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col cols="12" class="col-bordered">
              <span class="value-title">
                {{ 'Aquecer a água para lavagem do equipamento a 75ºC' }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.AguaEDiluicaoDetergenteAlcalinoRecomendacao2"
              cols="12"
              class="col-bordered"
            >
              <span class="value-title">
                {{
                  'Corrigir a dosagem do detergente alcalino clorado para o volume de água utilizado (ml por lavagem)'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>
        <v-col
          v-if="
            relatorio.SolucaoAguaAposLavagem != 'confirm' &&
            tipoDeOrdenha('SolucaoAguaAposLavagem')
          "
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Solução água + alcalino a 40°C (mínimo) após os 10 minutos de lavagem com alcalino clorado'
            }}
          </span>
          <v-col
            v-if="relatorio.SolucaoAguaAposLavagemRecomendacao1"
            cols="12"
            class="col-bordered"
          >
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col cols="12" class="col-bordered">
              <span class="value-title">
                {{ 'Deixar a máquina batendo por 8 a 10 minutos' }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>
        <v-col
          v-if="
            relatorio.EnxagueImediatoAposLavagem != 'confirm' &&
            tipoDeOrdenha('EnxagueImediatoAposLavagem')
          "
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'Enxágue imediato após a lavagem com detergente alcalino' }}
          </span>
          <v-col
            v-if="relatorio.EnxagueImediatoAposLavagemRecomendacao1"
            cols="12"
            class="col-bordered"
          >
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col cols="12" class="col-bordered">
              <span class="value-title">
                {{
                  'Realizar o enxágue imediato após a lavagem com detergente alcalino'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>
        <v-col
          v-if="
            relatorio.AguaEDiluicaoCorretaDetergenteAcido != 'confirm' &&
            tipoDeOrdenha('AguaEDiluicaoCorretaDetergenteAcido')
          "
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Temperatura da aguá e diluição correta do detergente ácido (lavagem 3x por semana ou conforme recomendação)'
            }}
          </span>
          <v-col
            v-if="relatorio.AguaEDiluicaoDetergenteAcidoRecomendacao1"
            cols="12"
            class="col-bordered"
          >
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col cols="12" class="col-bordered">
              <span class="value-title">
                {{
                  'Realizar a lavagem do equipamento com detergente ácido 3x por semana ou conforme a recomendação do fabricante'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>
        <v-col
          v-if="
            relatorio.OrdenhaBaldeaoPeCorreta != 'confirm' &&
            tipoDeOrdenha('OrdenhaBaldeaoPeCorreta')
          "
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Frequência correta de lavagem da linha de vácuo ( 1-2x por semana)'
            }}
          </span>
          <v-col
            v-if="relatorio.OrdenhaBaldeaoPeCorretaRecomendacao1"
            cols="12"
            class="col-bordered"
          >
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col cols="12" class="col-bordered">
              <span class="value-title">
                {{
                  'Realizar a lavagem da linha de vácuo pelo menos 1x por semana'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>
        <v-col
          v-if="
            relatorio.OrdenhaBaldeaoPeTempo != 'confirm' &&
            tipoDeOrdenha('OrdenhaBaldeaoPeTempo')
          "
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'Tempo de permanência do leite na lata inferior a 15 minutos' }}
          </span>
          <v-col
            v-if="relatorio.OrdenhaBaldeaoPeTempoRecomendacao1"
            cols="12"
            class="col-bordered"
          >
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.OrdenhaBaldeaoPeTempoRecomendacao1"
              cols="12"
              class="col-bordered"
            >
              <span class="value-title">
                {{
                  'Não deixar o leite na lata mais que 15 minutos sem refrigeração'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { convertNumberBR } from '../../../../utils/masks'
export default {
  name: 'IndicadoresQualidade',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      permissions: {
        0: 'UtensiliosBasicosLimpeza',
        1: 'EnxagueOrdenha',
        2: 'AguaEDiluicaoCorretaDetergenteAlcalino',
        3: 'SolucaoAguaAposLavagem',
        4: 'EnxagueImediatoAposLavagem',
        5: 'AguaEDiluicaoCorretaDetergenteAcido',
        6: 'OrdenhaBaldeaoPeCorreta',
        7: 'OrdenhaBaldeaoPeTempo',
      },

      ordernha: {
        0: 'TipoOrdenhaManual',
        1: 'TipoOrdenhaBaldeAoPe',
        2: 'TipoOrdenhaTransferidor',
        3: 'TipoOrdenhaLinhaBaixa',
        4: 'TipoOrdenhaLinhaMedia',
        5: 'TipoOrdenhaLinhaAlta',
      },

      ordenhaPermission: {
        TipoOrdenhaManual: [0, 1, 7],
        TipoOrdenhaBaldeAoPe: [0, 1, 2, 3, 4, 5, 6, 7],
        TipoOrdenhaTransferidor: [0, 1, 2, 3, 4, 5],
        TipoOrdenhaLinhaBaixa: [0, 1, 2, 3, 4, 5],
        TipoOrdenhaLinhaMedia: [0, 1, 2, 3, 4, 5],
        TipoOrdenhaLinhaAlta: [0, 1, 2, 3, 4, 5],
      },
    }
  },
  methods: {
    convertNumberBR,
    tipoDeOrdenha(set) {
      if (!this.relatorio[set]) return false

      let result = false
      Object.keys(this.ordernha).forEach(key => {
        if (this.relatorio[this.ordernha[key]]) {
          const permissions = this.ordenhaPermission[this.ordernha[key]]

          const num = Object.values(this.permissions).findIndex(
            item => item === set,
          )
          if (permissions.includes(num)) result = true
        }
      })

      return result
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
