var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"ma-4"},[_c('v-card-title',{staticStyle:{"text-align":"left"}},[_vm._v("Listagem das principais inconformidades")]),_c('v-card-text',[_c('v-row',{staticClass:"pa-3"},[(
          _vm.relatorio.step_8_ambiental_de_descanso ||
          _vm.relatorio.step_8_sala_de_ordenha ||
          _vm.relatorio.step_8_limpeza_do_equipamento ||
          _vm.relatorio.step_8_desregulagem_do_tanque ||
          _vm.relatorio.step_8_tanque_de_resfriamento ||
          _vm.relatorio.step_8_coleta_do_leite_superior_a_48_horas ||
          _vm.relatorio.step_8_atraso_no_resfriamento_do_leite
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Inconformidades")]),(_vm.relatorio.step_8_ambiental_de_descanso)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Ambiente de descanso dos animais com acúmulo de barro ")])]):_vm._e(),(_vm.relatorio.step_8_sala_de_ordenha)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Sala de ordenha sem higienização adequada ")])]):_vm._e(),(_vm.relatorio.step_8_limpeza_do_equipamento)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Limpeza do equipamento de ordenha inadequada ")])]):_vm._e(),(_vm.relatorio.step_8_desregulagem_do_tanque)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Desregulagem do tanque de resfriamento (termostato) ")])]):_vm._e(),(_vm.relatorio.step_8_tanque_de_resfriamento)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Tanque não refrigera o leite a 4 ºC em duas horas ")])]):_vm._e(),(_vm.relatorio.step_8_coleta_do_leite_superior_a_48_horas)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Coleta do leite superior a 48 horas ")])]):_vm._e(),(_vm.relatorio.step_8_atraso_no_resfriamento_do_leite)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Atraso no resfriamento do leite (Balde ao pé, Ordenha manual) ")])]):_vm._e()],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }