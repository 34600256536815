var render = function render(){var _vm=this,_c=_vm._self._c;return (
    (_vm.relatorio.ImagensAmbientePreOrdenha &&
      _vm.relatorio.ImagensAmbientePreOrdenha.length > 0) ||
    (_vm.relatorio.ImagensRotinaDeOrdenha &&
      _vm.relatorio.ImagensRotinaDeOrdenha.length > 0) ||
    (_vm.relatorio.ImagensRotinaDeLimpeza &&
      _vm.relatorio.ImagensRotinaDeLimpeza.length > 0) ||
    (_vm.relatorio.ImagensFuncionamentoTanque &&
      _vm.relatorio.ImagensFuncionamentoTanque.length > 0)
  )?_c('v-card',{staticClass:"ma-4"},[_c('v-card-title',{staticStyle:{"text-align":"left"}},[_vm._v("Imagens em anexo")]),_c('v-card-text',[_c('v-row',{staticClass:"pa-3"},[(
          _vm.relatorio.ImagensAmbientePreOrdenha != null &&
          _vm.relatorio.ImagensAmbientePreOrdenha.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Imagens ambiente (pré-ordenha)")]),_c('br'),_vm._l((_vm.relatorio.ImagensAmbientePreOrdenha),function(imagem,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[(imagem.id)?_c('v-img',{attrs:{"src":_vm.getImagem(imagem.id),"contain":"","max-width":"600px","max-height":"600px","alt":"imagem"}}):_vm._e()],1)})],2):_vm._e(),(
          _vm.relatorio.ImagensRotinaDeOrdenha != null &&
          _vm.relatorio.ImagensRotinaDeOrdenha.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Imagens rotina de ordenha")]),_c('br'),_vm._l((_vm.relatorio.ImagensRotinaDeOrdenha),function(imagem,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[(imagem.id)?_c('v-img',{attrs:{"src":_vm.getImagem(imagem.id),"contain":"","max-width":"600px","max-height":"600px","alt":"imagem"}}):_vm._e()],1)})],2):_vm._e(),(
          _vm.relatorio.ImagensRotinaDeLimpeza != null &&
          _vm.relatorio.ImagensRotinaDeLimpeza.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Imagens rotina de limpeza")]),_c('br'),_vm._l((_vm.relatorio.ImagensRotinaDeLimpeza),function(imagem,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[(imagem.id)?_c('v-img',{attrs:{"src":_vm.getImagem(imagem.id),"contain":"","max-width":"600px","max-height":"600px","alt":"imagem"}}):_vm._e()],1)})],2):_vm._e(),(
          _vm.relatorio.ImagensFuncionamentoTanque != null &&
          _vm.relatorio.ImagensFuncionamentoTanque.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Imagens do funcionamento do tanque")]),_c('br'),_vm._l((_vm.relatorio.ImagensFuncionamentoTanque),function(imagem,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[(imagem.id)?_c('v-img',{attrs:{"src":_vm.getImagem(imagem.id),"contain":"","max-width":"600px","max-height":"600px","alt":"imagem"}}):_vm._e()],1)})],2):_vm._e()],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }