<template>
  <v-card class="ma-4">
    <v-card-title style="text-align: left">Ambiente (pré-ordenha)</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          v-if="relatorio.UberesETetosLimpos == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{ 'Úberes e tetos limpos antes da ordenha' }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.SalaOrdenhaEsperaLeiteLimpa == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Sala de ordenha, sala de espera e sala de leite totalmente limpas'
            }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.RealizarLimpezaEquipamentos == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Tanque de expansão, latas e demais utensílios que fazem contato direto com o leite estão limpos e higienizados'
            }}
          </span>
        </v-col>
        <span class="pa-3"></span>
        <v-col
          v-if="relatorio.UberesETetosLimpos != 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'Úberes e tetos limpos antes da ordenha' }}
          </span>
          <v-col
            v-if="relatorio.UberesETetosLimposRecomendacao1"
            cols="12"
            class="col-bordered"
          >
            <span class="value-title">
              {{ 'Recomendações:' }}
            </span>
            <v-col cols="12" class="col-bordered">
              <span class="value-title">
                {{ 'Manter limpas as áreas de descanso das vacas' }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.UberesETetosLimposRecomendacao2"
              cols="12"
              class="col-bordered"
            >
              <span class="value-title">
                {{ 'Cercar as áreas de descanso com acúmulo de barro' }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.UberesETetosLimposRecomendacao3"
              cols="12"
              class="col-bordered"
            >
              <span class="value-title">
                {{ 'Melhorar a higienização da sala de ordenha e anexos' }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.UberesETetosLimposRecomendacao4"
              cols="12"
              class="col-bordered"
            >
              <span class="value-title">
                {{ 'Realizar pré-dipping de forma correta e eficiente' }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>
        <v-col
          v-if="relatorio.SalaOrdenhaEsperaLeiteLimpa != 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Sala de ordenha, sala de espera e sala de leite totalmente limpas'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Melhorar a higienização dos seguintes equipamentos:' }}
            </span>
            <v-col
              v-if="relatorio.HigienizacaoTanqueDeLeite"
              cols="12"
              class="col-bordered"
            >
              <span class="value-title">
                {{ 'Tanque de leite' }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.HigienizacaoLatoesDeLeite"
              cols="12"
              class="col-bordered"
            >
              <span class="value-title">
                {{ 'Latões de leite' }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.HigienizacaoBaldes"
              cols="12"
              class="col-bordered"
            >
              <span class="value-title">
                {{ 'Baldes' }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.HigienizacaoDemaisUtensilios"
              cols="12"
              class="col-bordered"
            >
              <span class="value-title">
                {{ 'Demais utensílios' }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.SalaOrdenhaEsperaLeiteRecomendacao"
              cols="12"
              class="col-bordered"
            >
              <span class="value-title">
                {{ 'Recomendações:' }}
              </span>
              <br />
              <span class="value-title">
                {{ 'Melhorar a higienização da sala de ordenha e anexos' }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>
        <v-col
          v-if="relatorio.RealizarLimpezaEquipamentos != 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Tanque de expansão, latas e demais utensílios que fazem contato direto com o leite estão limpos e higienizados'
            }}
          </span>
          <v-col
            v-if="relatorio.RealizarLimpezaTanqueDeLeite"
            cols="12"
            class="col-bordered"
          >
            <span class="value-title">
              {{ 'Melhorar a higienização dos seguintes equipamentos:' }}
            </span>
            <v-col cols="12" class="col-bordered">
              <span class="value-title">
                {{ 'Tanque de leite' }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.RealizarLimpezaLatoesDeLeite"
              cols="12"
              class="col-bordered"
            >
              <span class="value-title">
                {{ 'Latões de leite' }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.RealizarLimpezaBaldes"
              cols="12"
              class="col-bordered"
            >
              <span class="value-title">
                {{ 'Baldes' }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.RealizarLimpezaDemaisUtensilios"
              cols="12"
              class="col-bordered"
            >
              <span class="value-title">
                {{ 'Demais utensílios' }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.RealizarLimpezaRecomendacao"
              cols="12"
              class="col-bordered"
            >
              <span class="value-title">
                {{ 'Recomendações:' }}
              </span>
              <br />
              <span class="value-title">
                {{ 'Melhorar a higienização da sala de ordenha e anexos' }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { convertNumberBR } from '../../../../utils/masks'
export default {
  name: 'IndicadoresQualidade',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    convertNumberBR,
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
