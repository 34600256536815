<template>
  <v-card
    v-if="
      (relatorio.ImagensAmbientePreOrdenha &&
        relatorio.ImagensAmbientePreOrdenha.length > 0) ||
      (relatorio.ImagensRotinaDeOrdenha &&
        relatorio.ImagensRotinaDeOrdenha.length > 0) ||
      (relatorio.ImagensRotinaDeLimpeza &&
        relatorio.ImagensRotinaDeLimpeza.length > 0) ||
      (relatorio.ImagensFuncionamentoTanque &&
        relatorio.ImagensFuncionamentoTanque.length > 0)
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left">Imagens em anexo</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          v-if="
            relatorio.ImagensAmbientePreOrdenha != null &&
            relatorio.ImagensAmbientePreOrdenha.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Imagens ambiente (pré-ordenha)</span>
          <br />
          <v-chip
            v-for="(imagem, index) in relatorio.ImagensAmbientePreOrdenha"
            :key="index"
            class="ma-1"
          >
            <v-img
              v-if="imagem.id"
              :src="getImagem(imagem.id)"
              contain
              max-width="600px"
              max-height="600px"
              alt="imagem"
            />
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ImagensRotinaDeOrdenha != null &&
            relatorio.ImagensRotinaDeOrdenha.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Imagens rotina de ordenha</span>
          <br />
          <v-chip
            v-for="(imagem, index) in relatorio.ImagensRotinaDeOrdenha"
            :key="index"
            class="ma-1"
          >
            <v-img
              v-if="imagem.id"
              :src="getImagem(imagem.id)"
              contain
              max-width="600px"
              max-height="600px"
              alt="imagem"
            />
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ImagensRotinaDeLimpeza != null &&
            relatorio.ImagensRotinaDeLimpeza.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Imagens rotina de limpeza</span>
          <br />
          <v-chip
            v-for="(imagem, index) in relatorio.ImagensRotinaDeLimpeza"
            :key="index"
            class="ma-1"
          >
            <v-img
              v-if="imagem.id"
              :src="getImagem(imagem.id)"
              contain
              max-width="600px"
              max-height="600px"
              alt="imagem"
            />
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ImagensFuncionamentoTanque != null &&
            relatorio.ImagensFuncionamentoTanque.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Imagens do funcionamento do tanque</span>
          <br />
          <v-chip
            v-for="(imagem, index) in relatorio.ImagensFuncionamentoTanque"
            :key="index"
            class="ma-1"
          >
            <v-img
              v-if="imagem.id"
              :src="getImagem(imagem.id)"
              contain
              max-width="600px"
              max-height="600px"
              alt="imagem"
            />
          </v-chip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { ENV_APP } from '../../../../../env'
export default {
  name: 'ImagensAnexo',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    maskIdentify(raw) {
      return raw ? raw.split('|') : ['Nenhum animal identificado']
    },
    getImagem(idImagem) {
      return `${ENV_APP.apiUri}/download-image/${idImagem}`
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
